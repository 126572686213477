<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-staff-vehicle' }">{{
            $t("MENU.ITEM.SETTINGS.STAFF")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingStaffVehicle"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>

          <v-overlay :value="!firstLoader && isLoadingStaffVehicle">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingStaffVehicle"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_STAFF_VEHICLE } from "@/modules/staff-vehicle/store/staffvehicle.module";
import { FETCH_FUELS_TYPES } from "@/modules/fuels-types/store/fuel-type.module";
import { FETCH_ALL_STAFF } from "@/modules/staff/store/staff.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      staff: [],
      fuel_type:[],
    };
  },
  components: {
    KTCard,
    FormBuilder,
    FormInput,
    SaveButtonDropdown
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$i18n.t('MENU.ITEM.STAFF.STAFF_VEHICLE'), route: { name: "list-staff-vehicle" } },
      { title: this.$i18n.t('LABELS.ADD_NEW') }
    ]);
    this.$store.dispatch(FETCH_FUELS_TYPES).then((data)=>{
      this.fuel_type = data.data.items
    })
    this.$store.dispatch(FETCH_ALL_STAFF, "?active=1").then((data)=>{
      this.staff = data.data.items
    })
    vm.generateFormOutOfSchemaJson(this.schemaJson);


    vm.$nextTick(function() {
      vm.firstLoader = false;
    });
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoadingStaffVehicle" ]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "provider-name-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "4",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.REGISTRATION_PLATE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_REGISTRATION_PLATE",
              validation: "FORM.VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "registrationPlate"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            feedback_id: "unit-live-feedback",
            colSm: "4",
            divider: false,
            clearable: false,
            i18n: {
              label: "MENU.ITEM.STAFF.STAFF",
              placeholder: "MENU.ITEM.STAFF.STAFF"
            },
            options: this.staff,
            validations: {
              required: true
            },
            model: "staffId"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            feedback_id: "unit-live-feedback",
            colSm: "4",
            divider: false,
            clearable: false,
            i18n: {
              label: "FORM_LABELS.MACHINE_FUEL_TYPE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_FUEL_TYPE"
            },
            options: this.fuel_type,
            validations: {
              required: true
            },
            model: "fuelTypeId"
          },
          {
            type: "switch",
            groupId: "staff-active-group",
            id: "staff-active-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "active-live-feedback",
            colSm: "auto",
            divider: true,
            default: true,
            i18n: {
              label: "FORM_LABELS.ACTIVE",
              placeholder: "FORM_PLACEHOLDERS.ACTIVE",
              validation: "FORM.VALID_ACTIVE"
            },
            validations: {
              required: true
            },
            model: "active"
          }


        ]
      };
    }
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );

      return tmpValidationObject;
  },
  methods: {
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_STAFF_VEHICLE, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-staff-vehicle",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-staff-vehicle" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-staff-vehicle" });
          }
        })
        .catch((response) => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
